<template>
  <section>
    <div>
      <div class="w-full mt-4 py-2">
        <div class="w-full">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filtros.ItemCode.value" @input="getArticulos" placeholder="Buscar articulo" />
          </span>
        </div>
        <DataTable
          :value="contratosStore._paginado_articulos.rows"
          class="text-xs p-datatable-sm mt-2"
          showGridlines
        >
          <Column field="ItemCode" header="Medicamentos"></Column>
          <Column field="ItemName" header="Nombre">
            <template #body="slotProps">
              <div class="flex gap-2">
                <p>
                  <span class="font-medium">Medicamento</span>
                  {{ slotProps.data.ItemName }}
                </p>
              </div>
              <div class="flex gap-2">
                <p>
                  <span class="font-medium">P. activo</span>
                  {{ slotProps.data.U_PHR_PrincActivo }}
                </p>
              </div>
            </template>
          </Column>
          <Column field="Agrupacion" header="Agrupacion"></Column>
          <Column field="Precio" :style="{ 'width': '5rem' }" header="Precio">
            <template #body="slotProps">
              <div class="flex gap-2">
                <p class="text-center">{{ $h.formatCurrency(slotProps.data.Precio) }}</p>
              </div>
            </template>
          </Column>
          <Column field="U_PHR_PrincActivo" header="Estado">
            <template #body="slotProps">
              <div class="flex gap-2 items-center">
                <div :class="slotProps.data.Status ? 'bg-green-600' : 'bg-red-600'"  class="h-2 w-2 rounded-full"></div>
                <p class="font-bold text-sm" :class="slotProps.data.Status ? 'text-green-600' : 'text-red-600'">{{ slotProps.data.Status ? 'Activo' : 'Inactivo' }}</p>
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator :rows="limit" @page="onPage($event)" :totalRecords="contratosStore._paginado_articulos.count" :rowsPerPageOptions="[10, 20, 30, 100, 500]"></Paginator>
      </div>
    </div>
    <Dialog modal v-model:visible="displayModalCrearArticulo" header="Crear articulo" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="w-full">
        <label class="w-full text-xs text-gray-600 italic" for="itemCode">Codigo del articulo</label>
        <InputText class="w-full mb-2" id="itemCode" type="text" v-model="dataCrearArticulo.ItemCode" />
        <label class="w-full text-xs text-gray-600 italic" for="tipo">Tipo</label>
        <Dropdown id="tipo" v-model="dataCrearArticulo.tipo" :options="tiposArticulo" class="w-full" />
        <div class="mt-4 flex justify-end gap-2">
          <Button @click="cerrarModal" severity="secondary" label="Cancelar" />
          <Button @click="crearArticulo" label="Guardar" />
        </div>
      </div>
    </Dialog>
    <Dialog v-model:visible="displayModalInstruccionesPlantilla" modal header="Importar articulos masivo" :style="{ width: '35rem' }">
      <div class="text-sm mb-4">
        <p class="font-medium">Pasos para ingresar medicamentos:</p>
        <DataTable :value="pasosDescargaPlantilla" size="small" class="text-xs" showGridlines>
          <Column field="campo" header="Campo"></Column>
          <Column field="condicion" header="Condición"></Column>
          <Column field="descripcion" header="Descripción"></Column>
        </DataTable>
        <span class="text-xs font-bold italic">!Importante: Los medicamentos ingresados en la plantilla deben pertenecer a la lista de precios, de lo contrario no se asociaran al contrato</span>
      </div>
      <div class="flex justify-content-end gap-2 text-right">
        <Button type="button" label="Cancelar" severity="secondary" @click="displayModalInstruccionesPlantilla = false"></Button>
        <Button @click="contratosStore.descargarPlantillaMasivoArticulos(), displayModalInstruccionesPlantilla = false" class="h-10" label="Descargar plantilla articulos" icon="pi pi-download" />
      </div>
    </Dialog>
  </section>
</template>
<script setup>
  import { useContratosStore } from '../../../stores/contratos.store'
  import { useListaPrecioStore } from '../../../stores/lista-precio.store'
  import { ref, onMounted, computed } from 'vue'
  import { FilterMatchMode } from 'primevue/api'
  import { useToast } from 'primevue/usetoast'
  import { useRoute } from 'vue-router'
  import Swal from 'sweetalert2'
  const toast = useToast()
  const route = useRoute()
  const offset = ref(0)
  const limit = ref(10)
  const displayModalCrearArticulo = ref(false)
  const displayModalInstruccionesPlantilla = ref(false)
  const params = computed(() => {
    return {
      offset: offset.value,
      limit: limit.value
    }
  })
  const dataCrearArticulo = ref({
    ItemCode: '',
    tipo: '',
    ListPrice: 0
  })
  const pasosDescargaPlantilla = ref([
    {
      campo: 'Id',
      condicion: 'Obligatorio',
      descripcion: 'Se digita el id del código del medicamento que se encuentra en la tabla articulos de la base de datos'
    },
    {
      campo: 'ItemCode',
      condicion: 'Obligatorio',
      descripcion: 'Se digita el código del medicamento relacionado'
    },
    {
      campo: 'TipoArt',
      condicion: 'Opcional',
      descripcion: 'Se digita para CRONICO -> 0 ó para AGUDO -> 1'
    }
  ])
  const tiposArticulo = ref(['CRONICO', 'AGUDO'])
  const contratosStore = useContratosStore()
  const listaPrecioStore = useListaPrecioStore()
  const filtros = ref({
      ItemCode: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const getArticulos = () => {
    const busqueda = {}
    for (const [key, value] of Object.entries(filtros.value)) {
      if (value.value) {
        busqueda[key] = value.value
      }
    }
    const parametros = {
      ...params.value,
      ...busqueda
    }
    contratosStore.paginarArticulos(route.params.id, parametros)
  }
  const onPage = ({ first, rows }) => {
    offset.value = first
    limit.value = rows
    getArticulos()
  }
  const crearArticulo = () => {
    if (!contratosStore._articulos.lista_precio_id?.ListPriceCL) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una lista de precio para coontinuar', life: 5000 })
    const payload = {
      ItemCode: dataCrearArticulo.value.ItemCode.trim(),
      ListPrice: contratosStore._articulos.lista_precio_id.ListPriceCL,
      tipo: dataCrearArticulo.value.tipo
    }
    contratosStore.crearArticulosContrato(route.params.id, { ListPrice: contratosStore._articulos.lista_precio_id.ListPriceCL, data: [payload] }).then((data) => {
      if (data) {
        Swal.fire({
          title: 'Guardado',
          text: 'Articulo agregado',
          icon: 'success'
        }).then(() => {
          getArticulos()
        })
        cerrarModal()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al ingresar el articulo'
        })
      }
    })
  }
  const cerrarModal = () => {
    displayModalCrearArticulo.value = false
    dataCrearArticulo.value = {
      ItemCode: '',
      tipo: ''
    }
  }
  onMounted(() => {
    listaPrecioStore.listarListasPrecio()
    getArticulos()
  })
</script>
